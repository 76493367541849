var WIDTH = $(window).width();
var slidersOptions = {
  firstScreenSliderOptions: {
    infinite: true,
    arrows: false,
    dots: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  },
  textTestimonialsSliderOptions: {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('#textTestimonialsSliderPrev'),
    nextArrow: $('#textTestimonialsSliderNext'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
}

function headerMenu() {
  var htmlBody = $('html, body');
  var menuWrappers = $('.menu-item-has-children');
  var menuSubmenus = $('.submenu__wrapper');
  var boxShadow = $('#boxShadow');

  function closeMenu() {
    menuWrappers.add(menuSubmenus).removeClass('active');
    if (WIDTH > 667) {
      htmlBody.removeClass('overflow');
      boxShadow.removeClass('active');
    }
  }

  function openMenu(e) {
    var currentMenuWrapper = $(this).closest('.menu-item-has-children');
    var currentMenuSubmenu = currentMenuWrapper.find('.submenu__wrapper');
    currentMenuSubmenu ? e.preventDefault() : null;

    function openMenu() {
      menuWrappers.add(menuSubmenus).removeClass('active');
      currentMenuWrapper.add(currentMenuSubmenu).addClass('active');
      if (WIDTH > 667) {
        htmlBody.addClass('overflow');
        boxShadow.addClass('active');
      }
    }

    switch (
      currentMenuSubmenu &&
      currentMenuWrapper.add(currentMenuSubmenu).hasClass('active')
    ) {
      case true:
        closeMenu();
        break;
      case false:
        openMenu();
        break;
      default:
        break;
    }
  }

  function outerCLose(e) {
    e.target.id === 'boxShadow' ? closeMenu() : null;
  }

  function openCloseMobileMenu() {
    var mobileMenuBtn = $('#mobileMenuBtn');
    var mobileMenu = $('.header__menu-wrapper');

    function openMobileMenu() {
      htmlBody.addClass('overflow');
      mobileMenuBtn.add(mobileMenu).add(boxShadow).addClass('active');
    }

    function closeMobileMenu() {
      htmlBody.removeClass('overflow');
      mobileMenuBtn
        .add(mobileMenu)
        .add(boxShadow)
        .add(menuWrappers)
        .add(menuSubmenus)
        .removeClass('active');
    }

    switch (mobileMenuBtn.hasClass('active')) {
      case true:
        closeMobileMenu();
        break;
      case false:
        openMobileMenu();
        break;
      default:
        break;
    }
  }

  $(document).on('click', '.menu-item-has-children a', openMenu);
  $(document).on('click', '.submenu__close', closeMenu);
  $(document).on('click', outerCLose);
  $(document).on('click', '#mobileMenuBtn', openCloseMobileMenu);
}

function initCustomersFirstSlider() {
  var customersFirstSliderWrapper = $('#customersFirstSliderWrapper');
  var customersFirstSliderOptions = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('#customersFirstSliderPrev'),
    nextArrow: $('#customersFirstSliderNext'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (customersFirstSliderWrapper.hasClass('init_slider')) {
    var customersFirstSlider = customersFirstSliderWrapper.find(
      '.customers__first-slider'
    );
    var sectionWrapper =
      customersFirstSliderWrapper.closest('.customers__first');
    sectionWrapper.addClass('inited_slider');
    customersFirstSlider.slick(customersFirstSliderOptions);
  }
}

function initGreenBlocksSlider() {
  var greenBlockWrapper = $('.green__blocks-slider-wrapper');
  var greenBlockSliderOptions = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (greenBlockWrapper.hasClass('init_slider')) {
    var greenBlockSlider = greenBlockWrapper.find('.green__blocks-slider');

    greenBlockSlider.slick(greenBlockSliderOptions);
  }
}

function testimonialsSliders() {
  var testimonialsSliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: '#testimonialsNavigation',
    prevArrow: $('#testimonialsSliderPrev'),
    nextArrow: $('#testimonialsSliderNext'),
  };
  var testimonialsNavigation = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '#testimonialsSlider',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  function playCurrentVideo() {
    var currentPlayBtn = $(this);
    var currentVideoWrapper = currentPlayBtn.closest('.slide__video');
    var currentPoster = currentVideoWrapper.find('.slide__video-poster');
    var currentVideo = currentVideoWrapper.find('video');
    function playVideo() {
      currentVideo.trigger('play');
    }
    currentPoster.fadeOut(300, playVideo);
  }

  function stopAllVideos(event, slick, currentSlide, nextSlide) {
    var singleSlide = $('#testimonialsSlider').find('.slide .slide__video');
    var singleSlidePoster = singleSlide.find('.slide__video-poster');
    var singleSlideVideo = singleSlide.find('video');
    function stopVideo() {
      $(this).trigger('pause');
      $(this)[0].currentTime = 0;
    }
    singleSlideVideo.each(stopVideo);
    singleSlidePoster.fadeIn(300);
  }

  $('#testimonialsSlider').slick(testimonialsSliderOptions);
  $('#testimonialsNavigation').slick(testimonialsNavigation);
  $(document).on('click', '.slide__video-play', playCurrentVideo);
  $(document).on('beforeChange', '#testimonialsSlider', stopAllVideos);
}

function resourseTabs() {
  var hideSliderTimer;
  var sliderOptions = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  (function () {
    var firstNavBtn = $('#resourcesNavigation').find('a:first-child');
    var firstSliderId = firstNavBtn.attr('href');
    var firstSlider = $(firstSliderId);
    firstNavBtn.addClass('active');
    firstSlider.slick(sliderOptions).fadeIn(300);
  })();

  function selectNewTab(e) {
    e.preventDefault();
    var newTab = $(this);
    var newBlockId = newTab.attr('href');
    var newBlock = $(newBlockId);
    var allTabs = newTab.closest('#resourcesNavigation').find('a');
    var allBlocks = $('#resourcesBlocks').find('.resources__block');

    if (newTab.hasClass('active')) return null;

    function hideSliders() {
      allTabs.removeClass('active');
      newTab.addClass('active');
      clearTimeout(hideSliderTimer);
      hideSliderTimer = setTimeout(function () {
        allBlocks.each(function () {
          if ($(this).hasClass('slick-initialized')) $(this).slick('unslick');
        });
        newBlock.slick(sliderOptions);
      }, 300);
    }

    allBlocks.fadeOut(300, hideSliders);
    allBlocks.promise().done(function () {
      newBlock.delay(290).fadeIn(300);
    });
  }

  $(document).on('click', '.resources__navigation > a', selectNewTab);
}

function initTextBlocksSlider() {
  var sliderWrapper = $('.text__blocks-slider-wrapper');
  var sliderOptions = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('#textBlocksSliderPrev'),
    nextArrow: $('#textBlocksSliderNext'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  var greenSliderOptions = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('#greenBlocksSliderPrev'),
    nextArrow: $('#greenBlocksSliderNext'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  var whiteSliderOptions = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('#whiteBlocksSliderPrev'),
    nextArrow: $('#whiteBlocksSliderNext'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }


  sliderWrapper.each(function() {
    var sliderSection = $(this).closest('.text__blocks');
    var slider = $(this).find('.text__blocks-slider');

    if($(this).hasClass('init_slider') && sliderSection.hasClass('green_blocks')) {
      slider.slick(greenSliderOptions);
      return true;
    }
    
    if($(this).hasClass('init_slider') && sliderSection.hasClass('white_blocks')) {
      slider.slick(whiteSliderOptions);
      return true;
    }

    slider.slick(sliderOptions);
  });
}

function bannerWithVideo() {

  function openVideo() {
    var bannerSection = $(this).closest('.banner');
    var bannerVideoWrapper = bannerSection.find('.banner__video');
    var bannerVideo = bannerVideoWrapper.find('video');
    if(bannerSection && bannerSection.hasClass('with_video')) {
      bannerVideoWrapper.addClass('active');
      bannerVideo.removeAttr('autoplay loop muted playsinline').attr('controls', true);
      bannerVideo[0].currentTime = 0;
      bannerVideo.trigger('play');
    }
  }

  function closeVideo() {
    var bannerVideoWrapper = $(this).closest('.banner__video');
    var bannerVideo = bannerVideoWrapper.find('video');
    bannerVideo.removeAttr('controls').attr('autoplay', 'loop', 'muted', 'playsinline');
    bannerVideo[0].currentTime = 0;
    bannerVideo.trigger('play');
    bannerVideoWrapper.removeClass('active');
  }

  $(document).on('click', '.banner__content-video', openVideo);
  $(document).on('click', '.banner__video-close', closeVideo);
}

function innerAnimationList() {
  var listWrapper = $('#secondAnimatedList');
  var list = listWrapper.find('ul');
  listWrapper.length ? list.addClass('animated') : null;
}

function sliderInitializator(sliderBlock, sliderOptions) {
  $(sliderBlock).slick(sliderOptions);
}

function showMoreText() {
  var allContent = {};
  var textMaxLength = 150;
  var textTestionialSlider = $('#textTestimonialsSlider');
  var sliderSlides = textTestionialSlider.find('.slide');
  
  (function() {
    if(textTestionialSlider.length) {
      sliderSlides.each(function() {
        var testimonialSlideData = $(this).attr('data-number');
        var testimonialContent = $(this).find('.slide__content');
        var testimonialContentText = testimonialContent.find('*').text();
        var shortText;

        function cutText() {
          shortText = testimonialContentText.substr(0, textMaxLength) + '...';
          allContent[testimonialSlideData] = {
            short: shortText,
            full: testimonialContentText,
          };
          testimonialContent.empty().html('<p>' + shortText + '</p>');
        }
        
        switch(testimonialContentText.length > textMaxLength) {
          case true:
            cutText();
            break;
          default:
            break;
        }
      });
    }
  })();

  function showHideText(e) {
    e.preventDefault();
    var currentBtn = $(this);
    var currentSlide = currentBtn.closest('.slide');
    var currentSlideNumber = currentSlide.attr('data-number');
    var currentSlideContent = currentSlide.find('.slide__content');
    var currentSlideText = currentSlideContent.find('*').text();

    function showText(text) {
      currentBtn.text('Show less');
      oldText = currentSlideText;
      currentSlideContent.empty().html('<p>' + text + '</p>');
      currentSlide.addClass('showed');
    }
    
    function hideText(text) {
      currentBtn.text('Read more');
      currentSlideContent.empty().html('<p>' + text + '</p>');
      currentSlide.removeClass('showed');
    }

    for(item in allContent) {
      if(item === currentSlideNumber) {
        currentSlide.hasClass('showed') ? hideText(allContent[item].short) : showText(allContent[item].full);
      }
    }
  }

  function hideAllTexts(event, slick, currentSlide, nextSlide) {
    var slide = $('.text__testimonials-slider .slide');
    slide.each(function() {
      var slide = $(this);
      if(slide.hasClass('showed')) {
        var slideNumber = slide.attr('data-number');
        var slideContent = slide.find('.slide__content');
        var slideBtn = slide.find('.slide__more');
        slide.removeClass('showed');
        slideContent.empty().html('<p>' + allContent[slideNumber].short + '</p>');
        slideBtn.text('Read more');
      }
    });
  }

  $(document).on('click', '.slide__more', showHideText);
  $('#textTestimonialsSlider').on('beforeChange', hideAllTexts);
}

$(document).ready(function () {
  headerMenu();
  initCustomersFirstSlider();
  initGreenBlocksSlider();
  testimonialsSliders();
  resourseTabs();
  initTextBlocksSlider();
  bannerWithVideo();
  innerAnimationList();
  sliderInitializator('#firstScreenSlider', slidersOptions.firstScreenSliderOptions);
  sliderInitializator('#textTestimonialsSlider', slidersOptions.textTestimonialsSliderOptions);
  showMoreText();
});
